import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user';
import { map, share } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Contract } from '../_models/_contracts';
import {pathChart}  from './environmentchartparameter';

@Injectable({ providedIn: 'root' })
export class EvidenceService {
    loadThresholds(id: number): Observable<any> {
        return this.http.get<any>(environment.API_URL+"Get_EvidenceThresholdsbyContracts?id_contract="+id, this.populateHeader());
    }
    httpOptions = {};
    constructor(private http: HttpClient) { 
       
     }

    getAllContracts(): Observable<Contract[]> {

        return this.http.get<any>(environment.API_URL+"Get_EvidenceContracts", this.populateHeader());
    }

    getAllFreeFormReports(): Observable<Contract[]> {

        return this.http.get<any>(environment.API_URL+"Get_FFR_List", this.populateHeader());
    }
    

    getAllFreeFormReportsData(id:any, param:any): Observable<any>{

        //return this.http.get<any>(environment.API_URL+"Get_FFR_Data_byId?id="+param, this.populateHeader())
        return this.http.post<any>(environment.API_URL_NODE+"dataFreeFormReport/paramDataFFR?id="+id,param, this.populateHeader())
    }

    getAllFreeFormReportsChartByID(id:any, param:any): Observable<any>{

        //return this.http.get<any>(environment.API_URL+"Get_FFR_Chart_byId?id="+param, this.populateHeaderForBlob())
        return this.http.post<any>(environment.API_URL+"Get_FFR_Chart_byId?id="+id,param, this.populateHeaderForBlob())
        
    }


    getEvidenceIndicatorByContract(id_contract :any ): Observable<Contract[]> {

        return this.http.get<any>(environment.API_URL+"Get_EvidenceIndicators?id_contract="+id_contract, this.populateHeader());
    }

    getEvidenceIndicators( ): Observable<any[]> {

        return this.http.get<any>(environment.API_URL+"Get_EvidenceIndicators", this.populateHeader());
    }

    getEvidenceCalculationParameters( ): Observable<Contract[]> {

        return this.http.get<any>(environment.API_URL+"Get_EvidenceCalculationParameters", this.populateHeader());
    }

// New API
    Get_EvidenceContract_ById(id_contract: any ): Observable<any> {

        return this.http.get<any>(environment.API_URL+"Get_EvidenceContract_ById?id_contract="+id_contract, this.populateHeader());
    }
    Get_EvidenceIndicator_ByContract(id_contract: any ): Observable<any[]> {

        return this.http.get<any>(environment.API_URL+"Get_EvidenceIndicator_ByContract?id_contract="+id_contract, this.populateHeader());
    }

    getEvidenceThresholdById(id_threshold : any ): Observable<any> {

        return this.http.get<any>(environment.API_URL+"Get_EvidenceThreshold_ById?id_threshold="+id_threshold, this.populateHeader());
    }


    Get_EvidenceCustomer_ById( id_customer:any): Observable<any> {

        return this.http.get<any>(environment.API_URL+"Get_EvidenceCustomer_ById?id_customer="+id_customer, this.populateHeader());
    }

    Get_EvidenceCustomer_ByContract( id_contract:any ): Observable<any>{
        return this.http.get<any>(environment.API_URL+"Get_EvidenceCustomers_ByContract?id_contract="+id_contract, this.populateHeader());
    }


    Get_EvidenceService_ById( id_service:any ): Observable<any> {

        return this.http.get<any>(environment.API_URL+"Get_EvidenceService_ById?id_service="+id_service, this.populateHeader());
    }


    Get_EvidenceIndicators_ByTenant(id_tenant:any ): Observable<any> {

        return this.http.get<any>(environment.API_URL+"Get_EvidenceIndicators_ByTenant?id_tenant="+id_tenant, this.populateHeader());
    }

    Get_EvidenceTimeslot_ById( id_timeslot:any ): Observable<any> {

        return this.http.get<any>(environment.API_URL+"Get_EvidenceTimeslot_ById?id_timeslot="+id_timeslot, this.populateHeader());
    }

    Get_EvidenceUnitMeasure_ById( id_unit_measure:any ): Observable<any> {

        return this.http.get<any>(environment.API_URL+"Get_EvidenceUnitMeasure_ById?id_unit_measure="+id_unit_measure, this.populateHeader());
    }


    Get_EvidenceIndicator_ByVersion( id_version:any ): Observable<any> {

        return this.http.get<any>(environment.API_URL+"Get_EvidenceIndicator_ByVersion?id_version="+id_version, this.populateHeader());
    }

   
    Post_EvidenceValidityCheck(contract_id:number, period_start:any, period_end:any): Observable<any>{
        let body={
            'contract_id':contract_id,
            'period_start':period_start,
            'period_end':period_end
        }
        return this.http.post<any>(environment.API_URL_NODE+"booklet/POST_EvidenceValidityCheck",body,this.populateHeader());
    }
    

    Get_EvidenceTenants( ): Observable<any> {

        return this.http.get<any>(environment.API_URL+"Get_EvidenceTenants", this.populateHeader());
    }

    Get_EvidenceServices( ): Observable<any> {

        return this.http.get<any>(environment.API_URL+"Get_EvidenceServices", this.populateHeader());
    }



    Get_EvidenceCalculationParameters_ByContract( id_contract:any ): Observable<any> {

        return this.http.get<any>(environment.API_URL+"Get_EvidenceCalculationParameters_ByContract?id_contract="+id_contract, this.populateHeader());
    }
    // New API
    
    getChartReport(param:any): Observable<any> {
        //   let options_ : any = {
        //     responseType: "blob",
        //     headers: new HttpHeaders({
        //         'Authorization':  "Basic " + btoa(environment.API_Credential),
        //         'AuthToken': environment.token,
        //         'responseType': 'blob' as 'json'
        //       })
        // };
        return this.http.get<any>(environment.API_URL+"Get_xls_chart", this.populateHeaderForBlob());
    }

    getChartTemplate(): Observable<any> {
        let options_ : any = {
            responseType: "text"
        }
        console.log(pathChart+"/assets/Chart.xml");
      return this.http.get<any>(pathChart+"/assets/Chart.xml",options_);
    }

    loadCustomerAttributes(param:any ): any {
        return this.http.get<any>(environment.API_URL + "Get_EvidenceCustomer_ById?id_customer=" + param, this.populateHeader());
    }

   
    // populateddlFreeFormReportType(): Observable<any> {
    //     return this.http.get<any>(environment.API_URL + "Get_EvidenceCustomer_ById?id_customer=" + param.value, this.populateHeader());
    // }


    populateHeader(){
        
        const httpOptions = {
            headers: new HttpHeaders({
              'Authorization':  "Basic " + btoa(environment.API_Credential),
              'AuthToken': environment.token
            })
          };
          return httpOptions;
    }
    populateHeaderForBlob(){
        let header : any = {
            responseType: "blob",
            headers: new HttpHeaders({
                'Authorization':  "Basic " + btoa(environment.API_Credential),
                'AuthToken': environment.token,
                'responseType': 'blob' as 'json'
              })
        };
        return header
    }
}

function user<T>(arg0: string, user: any) {
    throw new Error('Function not implemented.');
}
