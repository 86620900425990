<style>
    .left-margin {
        margin-left: 2% !important;
    }
  </style>

  <main id="app-body" class="ms-welcome__main">
      <!-- <h1>{{currentUser.token}}</h1> -->
    <div id="sectionOne">
        <div class="d-flex justify-content-end mt-1">
            <button class="btn btn-danger" type="button" id="logoutButton" (click)="logout()">Logout</button>
        </div>
        <div class="input-group mb-3 d-flex flex-row mt-3" style="min-width: 271px;">
            <div class="input-group-prepend">
                <span class="input-group-text">Publish Type</span>
            </div>
            <select class="form-control w-auto" id="publishType"  (change)='setPublishType($event)'>
                <option value="1">Quick Publish</option>
                <option value="2">Massive Publish</option>
            </select>
        </div>
        <div class="input-group mb-3 d-flex flex-row" style="min-width: 271px;">
            <div class="input-group-prepend">
                <span class="input-group-text" style="width: 73px;">Contract</span>
            </div>
            <select class="form-control" style="width: 155px;" id="selectContracts"  #selectContracts (change)='handleContractSelection(selectContracts)'>
                <option value="-1">Please Choose Contract</option>
                <option *ngFor="let contract of contracts" [value]="contract.id_customer">{{contract.name}}</option>
            </select>
            <input type="text" class="form-control" id="selectedContracts" [value]="selectedContractsList" style="display: none; width: 155px;"/>
            <select id="selectMultipleContracts" data-placeholder="Begin typing a name to filter..." style="display: none; width: 155px;" multiple class="chosen-select form-control" #selectContracts >
                <option value="-1"></option>
                <option *ngFor="let contract of contracts" [value]="contract.id">{{contract.name}}</option>
            </select>
        </div>
        <div *ngIf="selectedContract && publishType=='1'">
            <div class="input-group mb-3 d-flex flex-row" style="min-width: 271px;">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 73px;">Start Date</span>
                </div>
                <input type="date" class="form-control" id="startDate" style="width: 155px;" (input)="updateStartDate($event)" value="{{selectedContract.valid_from}}" min="{{selectedContract.valid_from}}" max="{{selectedContract.valid_to}}"/>
            </div>
            <div class="input-group mb-3 d-flex flex-row" style="min-width: 271px;">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 73px;">End Date</span>
                </div>
                <input type="date" class="form-control" id="endDate" style="width: 155px;" (input)="updateEndDate($event)" value="{{selectedContract.valid_to}}" min="{{selectedContract.valid_from}}" max="{{selectedContract.valid_to}}"/>
            </div>
       </div>
       <div *ngIf="publishType=='2'">
        <div class="input-group mb-3 d-flex flex-row" style="min-width: 271px;">
            <div class="input-group-prepend">
                <span class="input-group-text" style="width: 73px;">Start Date</span>
            </div>
            <input type="datetime-local" class="form-control" id="startDates" style="width: 155px;" value="{{startDateMassive}}"/>
        </div>
        <div class="input-group mb-3 d-flex flex-row" style="min-width: 271px;">
            <div class="input-group-prepend">
                <span class="input-group-text" style="width: 73px;">End Date</span>
            </div>
            <input type="datetime-local" class="form-control" id="endDates" style="width: 155px;" value="{{endDateMassive}}" />
        </div>
   </div>
    </div>
    <div id="sectionTwo" class="mainSection"  style="margin-top: 2%;width: 100%;">
        <ul id="mainSectionTabs" class="nav nav-tabs">
            <li class="active"><a data-toggle="tab" href="#singleTab">Single</a></li>
            <li><a data-toggle="tab" href="#listTab">List</a></li>
            <li><a data-toggle="tab" href="#tableTab">Table</a></li>
        </ul>
        <div class="tab-content">
            <!-- Single Tab Section Starts Here -->
            <div id="singleTab" class="tab-pane fade  in active">
                <!-- <div>
                    <select id="ddSingleTabFilter" style="min-width: -webkit-fill-available;">
                        <option value="-1">Please Chose Filter</option>
                        <option *ngFor="let ddItem of ddSingleTabFilter" [value]="ddItem">{{ddItem}}</option>
                    </select>
                </div> -->
                <!-- sing tab category div starts here-->
                <div style="margin-top: 2%;width: 100%;">
                    <ul id="singleTabSectionTabs" class="nav nav-tabs">
                        <li class="active" (click)="onTabChange('customers')"><a data-toggle="tab" href="#singleTab_customersTab" title="Customers"><i class="fas fa-user"
                                    title="Customers"></i></a></li>
                        <li (click)="onTabChange('contracts')"><a data-toggle="tab" href="#singleTab_contractsTab"  title="Contract"><i class="fas fa-file-signature"
                                    title="Contract"></i></a></li>
                        <li (click)="onTabChange('services')"><a data-toggle="tab" href="#singleTab_servicesTab" title="Services"><i class="fas fa-tasks"
                                    title="Services"></i></a></li>
                        <li (click)="onTabChange('indicators')"><a data-toggle="tab" href="#singleTab_metricsTab" title="Metrics"><i class="fas fa-percent" title="Metrics"></i></a></li>
                        <li (click)="onTabChange('thresholds')"><a data-toggle="tab" href="#singleTab_thresholdTab" title="Threshold"><i class="fas fa-stream"
                            title="Threshold"></i></a></li>
                        <li (click)="onTabChange('parameters')"><a data-toggle="tab" href="#singleTab_parametersTab" title="Parameters"><i class="fas fa-cheese"
                                    title="Parameters"></i></a></li>
                        <li style="display: none"><a data-toggle="tab" href="#singleTab_attributesTab" title="Attributes"><i class="fab fa-elementor"
                                    title="Attributes"></i></a></li>
                        <li style="display: none"><a data-toggle="tab" href="#singleTab_workflowsTab" title="WorkFlow"><i class="fas fa-stream"
                                    title="WorkFlow"></i></a></li>
                        <li style="display: none"><a data-toggle="tab" href="#singleTab_reportsTab" title="Report"><i class="fas fa-file-alt"
                                    title="Report"></i></a></li>
                        <li><a data-toggle="tab" href="#singleTab_freeformreportTab" title="Report"><i class="fas fa-headset" title="Report"></i></a>
                        </li>
                        <li><a data-toggle="tab" href="#singleTab_variablesTab" title="Variables"><i class="fas fa-archive" title="Variables"></i></a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div id="singleTab_customersTab" class="tab-pane fade  in active">
                            <h3 style="color: #7d2317;">Customers</h3>
                            <div class=" " role="group" aria-label="Basic example">
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.Single.Customers">
                                            <td><input type="radio" id="{{item}}" name="singleTab_customersTab" checked value="{{item.key}}"></td>
                                            <td >{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #singleTab_customersTab></div>
                        </div>
                        <div id="singleTab_contractsTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Contract</h3>
                            <div class="" role="group" aria-label="Basic example">
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.Single.Contracts">
                                            <td><input type="radio" id="{{item}}" name="singleTab_contractsTab" checked value="{{item.key}}"></td>
                                            <td >{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #singleTab_contractsTab></div>
                        </div>
                        <div id="singleTab_servicesTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Services</h3>
                            <div class="" role="group" aria-label="Basic example">
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.Single.Services">
                                            <td><input type="radio" id="{{item}}" name="singleTab_servicesTab" checked value="{{item.key}}"></td>
                                            <td >{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #singleTab_servicesTab></div>
                        </div>
                        <div id="singleTab_metricsTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Metrics</h3>
                            <div class="" role="group" aria-label="Basic example">
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.Single.Indicators">
                                            <td><input type="radio" id="{{item}}" name="singleTab_metricsTab" checked value="{{item.key}}"></td>
                                            <td>{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #singleTab_metricsTab></div>
                        </div>
                        <div id="singleTab_thresholdTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Thresholds</h3>
                            <div class="" role="group" aria-label="Basic example">
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.Single.Thresholds">
                                            <td><input type="radio" id="{{item}}" name="singleTab_thresholdTab" checked value="{{item.key}}"></td>
                                            <td >{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #singleTab_thresholdTab></div>
                        </div>
                        <div id="singleTab_parametersTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Parameters</h3>
                            <div class="" role="group" aria-label="Basic example">
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.Single.Paramters">
                                            <td><input type="radio" id="{{item}}" name="singleTab_parametersTab" checked value="{{item.key}}"></td>
                                            <td >{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #singleTab_parametersTab></div>
                        </div>
                        <div id="singleTab_attributesTab" class="tab-pane fade" style="display: none">
                            
                            <h3 style="color: #7d2317;">Attirbutes</h3>
                            <div class="btn-group-vertical" role="group" aria-label="Basic example">
                                <div *ngFor="let item of Tabs.Single.Attributes">
                                    <input type="radio" id="{{item}}" name="singleTab_attributesTab" checked value="{{item.key}}">
                                    <label for="{{item}}">{{customizeView(item.val)}}</label><br>
                                </div>
                            </div>
                        </div>
                        <div id="singleTab_workflowsTab" class="tab-pane fade" style="display: none">
                            <h3 style="color: #7d2317;">WorkFlow</h3>
                            <p>Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta
                                sunt
                                explicabo.</p>
                        </div>
                        <div id="singleTab_reportsTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Report</h3>
                            <div class="container">
  
                            
                           <select id="ddlReportType">
                               <option value="-1">Please Select Report Type</option>
                               <option value="chart">Chart</option>
                               <option value="table">Table</option>
                           </select>
                           <br>
                           <br>
                           <select id="selectKPI">
                             <option value="-1">Please Choose Metrics     </option>
                             <option *ngFor="let item of evidenceIndicators" [value]="item.id">{{item.name}}</option>
                           </select>
                           <br>
                           <br>
                           <select id="selectGranularity" onchange="loadCalendar(this)">
                             <option value="years">Yearly </option>
                             <option value="months">Monthly</option>
                             <option value="days">Daily</option>
                           </select>
                           <br>
                           <br>
                        </div>
                           <div class="row" id="calendar" >
                            <div class="container">
                                <div class='col-md-5'>
                                   <div class="form-group">
                                      <div class='input-group date' id='datetimepicker1'>
                                         <input type='text' id="txtDatetimepicker1" class="form-control" />
                                         <span class="input-group-addon">
                                         <span class="glyphicon glyphicon-calendar"></span>
                                         </span>
                                      </div>
                                   </div>
                                </div>
                                <div class='col-md-5'>
                                   <div class="form-group">
                                      <div class='input-group date' id='datetimepicker2'>
                                         <input type='text' id="txtDatetimepicker2"  class="form-control" />
                                         <span class="input-group-addon">
                                         <span class="glyphicon glyphicon-calendar"></span>
                                         </span>
                                      </div>
                                   </div>
                                </div>
                             </div>
                           </div>
                        </div>
                        <div id="singleTab_freeformreportTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Report</h3>
                            <div class="container">
                                <div class="input-group mb-3 d-flex flex-row" style="min-width: 271px;">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" style="width: 97px;">Report</span>
                                    </div>
                                    <select id="ddlFreeFormReportType" class="form-control" (change)="resetSelectOprion()" style="width: 155px; overflow: hidden;" #ddlFreeFormReportType (change)="loadfreeFormReportColumns(ddlFreeFormReportType)">
                                        <option value="-1">Please Select Report Type</option>
                                        <option *ngFor="let item of freeFormReports" [value]="item.id">{{item.name}}</option>
                                    </select>
                                </div>
                                
                                <div class="input-group mb-3 d-flex flex-row">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Presentation Type</span>
                                    </div>
                                    <!-- <select id="ddlFreeFormReportPresentationType" #ddlFreeFormReportPresentationType (change)="loadfreeFormReportColumns(ddlFreeFormReportType)">
                                        <option value="table" selected>Table</option>
                                        <option value="chart">Chart</option>
                                    </select> -->
                                    <select id="ddlFreeFormReportPresentationType" class="form-control w-auto" #ddlFreeFormReportPresentationType (change)="toggleColumnSelection(ddlFreeFormReportPresentationType)">
                                        <option value="table" selected>Table</option>
                                        <option value="chart">Chart</option>
                                    </select>
                                </div>
                                <br>
                                <div  [hidden]="!tableSelected">
                                    <!-- <p>{{freeFormReportColumns.length}}</p> -->
                                    <div *ngIf="freeFormReportColumns.length>0; else elseBlock">
                                        <!-- Please Select Column(s): -->
                                        <h4 style="color: #7d2317;">Column(s)</h4>
                                        <div class="btn-group-vertical" role="group" >
                                            <div *ngFor="let item of freeFormReportColumns">
                                                <input type="checkbox" id="{{item}}" name="freeFormReportColumn" (change)="udpateColumnTable(item)"  value="{{item}}">
                                                <label for="{{item}}">{{item[0].toUpperCase() + item.slice(1)}}</label><br>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-template #elseBlock>
                                    </ng-template>
                                    <div *ngIf="parameterSelected.length>0">
                                    <h4 style="color: #7d2317;">Parameters</h4>
                                        <div *ngFor="let item of freeFormReportParameters; let i = index">
                                            <div class="input-group mb-3 d-flex flex-row" style="min-width: 251px;">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" style="width: 95px;">{{item.name}}</span>
                                                </div>
                                                <!-- <input class="form-control w-25" value="{{item.value}}"> -->
                                                <select class="form-control w-25" [(ngModel)]="parameterSelected[i]">
                                                    <option [value]="item.value">{{item.value}}</option>
                                                    <option *ngFor="let items of this.allVariables" [value]="'variable.'+items.name+'#variable#value='+items.value+'#id='+items.id+'#type='+items.type">{{"variable."+items.name}}</option>
                                                    <!-- <option *ngFor="let items of this.Tabs.Single.Customers" [value]="'customers.'+items.key+'#tag#singleTab_customersTab'">{{"customers."+items.val}}</option>
                                                    <option *ngFor="let items of this.Tabs.Single.Contracts" [value]="'contracts.'+items.key+'#tag#singleTab_contractsTab'">{{"contracts."+items.val}}</option>
                                                    <option *ngFor="let items of this.Tabs.Single.Services" [value]="'services.'+items.key+'#tag#singleTab_servicesTab'">{{"services."+items.val}}</option>
                                                    <option *ngFor="let items of this.Tabs.Single.Thresholds" [value]="'threshold.'+items.key+'#tag#singleTab_thresholdTab'">{{"threshold."+items.val}}</option>
                                                    <option *ngFor="let items of this.Tabs.Single.Paramters" [value]="'parameters.'+items.key+'#tag#singleTab_parametersTab'">{{"parameters."+items.val}}</option> -->
                                                  </select>
                                                <div class="input-group-append">
                                                    <span class="input-group-text" style="width: 47px;">{{item.type}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div [hidden]="tableSelected">
                                    <div *ngIf="parameterSelected.length>0">
                                        <h4 style="color: #7d2317;">Parameters</h4>
                                        <div *ngFor="let item of freeFormReportParameters; let i = index">
                                            <div class="input-group mb-3 d-flex flex-row" style="min-width: 251px;">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" style="width: 95px;">{{item.name}}</span>
                                                </div>
                                                <!-- <input class="form-control w-25" value="{{item.value}}"> -->
                                                <select class="form-control w-25" [(ngModel)]="parameterSelected[i]">
                                                    <option [value]="item.value">{{item.value}}</option>
                                                    <option *ngFor="let items of this.allVariables" [value]="'variable.'+items.name+'#variable#value='+items.value+'#id='+items.id+'#type='+items.type">{{"variable."+items.name}}</option>
                                                    <!-- <option *ngFor="let items of this.Tabs.Single.Customers" [value]="'customers.'+items.key+'#tag#singleTab_customersTab'">{{"customers."+items.val}}</option>
                                                    <option *ngFor="let items of this.Tabs.Single.Contracts" [value]="'contracts.'+items.key+'#tag#singleTab_contractsTab'">{{"contracts."+items.val}}</option>
                                                    <option *ngFor="let items of this.Tabs.Single.Services" [value]="'services.'+items.key+'#tag#singleTab_servicesTab'">{{"services."+items.val}}</option>
                                                    <option *ngFor="let items of this.Tabs.Single.Thresholds" [value]="'threshold.'+items.key+'#tag#singleTab_thresholdTab'">{{"threshold."+items.val}}</option>
                                                    <option *ngFor="let items of this.Tabs.Single.Paramters" [value]="'parameters.'+items.key+'#tag#singleTab_parametersTab'">{{"parameters."+items.val}}</option> -->
                                                  </select>
                                                <div class="input-group-append">
                                                    <span class="input-group-text" style="width: 47px;">{{item.type}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="singleTab_variablesTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Variables</h3>
                            <div>
                                <div *ngFor="let items of this.allVariables" class="border border-primary p-3 rounded mt-2">
                                    <div class="input-group mb-3 d-flex flex-row" style="min-width: 251px;">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" style="width: 70px;">{{items.name}}</span>
                                        <span></span>
                                        </div>
                                        <input type="text" class="form-control w-25" [(ngModel)]="items.value">
                                        <div class="input-group-append">
                                            <span class="input-group-text" style="width: 47px;">{{items.type}}</span>
                                        </div>
                                    </div>
                                    <button class="btn btn-sm btn-success" id="buttonUpdateVariables" (click)="updateVariables(items.id, items.value, items.type)" title="Update variable" data-toggle="tooltip" data-placeholder="top"><i class="fas fa-sync"></i></button>
                                    <button class="btn btn-sm btn-danger ml-2" id="buttonDeleteVariables" (click)="deleteVariables(items.id, items.name)" title="Remove variable" data-toggle="tooltip" data-placeholder="top"><i class="fa fa-minus"></i></button>
                                </div>
                            </div>
                           <br> 
                            <div>
                                <h4 style="color: #7d2317;">Create new variable</h4>
                                    <div class="input-group mb-3 d-flex flex-row" style="min-width: 251px;">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" style="width: 70px;">Name</span>
                                        </div>
                                        <input type="text" class="form-control w-25" [(ngModel)]="Variable.name" placeholder="Insert name of variable">
                                    </div>
                                    <div class="input-group mb-3 d-flex flex-row" style="min-width: 251px;">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" style="width: 70px;">Value</span>
                                        </div>
                                        <input type="text" class="form-control w-25" [(ngModel)]="Variable.value" placeholder="Insert value of variable">
                                    </div>
                                    <div class="input-group mb-3 d-flex flex-row" style="min-width: 251px;">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" style="width: 70px;">Type</span>
                                        </div>
                                        <select id="variablesDropDownType" class="form-control w-25" [(ngModel)]="Variable.type">
                                            <option selected>Choose the type of variable</option>
                                            <option value="integer">integer</option>
                                            <option value="float">float</option>
                                            <option value="text">text</option>
                                        </select>
                                    </div>
                                    <div>
                                        <button id="buttonCreateVariable" (click)="createVariable()" class="btn btn-success"><i class="fa fa-plus"></i></button>
                                    </div>
                            </div>                               
                        </div>
                    </div>
                </div>
                <!-- sing tab category div ends here-->
            </div>
  
  
  
  
  
  
  
  
            <!-- Single Tab Section Ends Here -->
  
  
  
            <!-- List Tab Section Starts Here -->
  
  
            <div id="listTab" class="tab-pane fade">
                <div style="margin-top: 2%;width: 100%;">
                    <ul  id="listTabSectionTabs"  class="nav nav-tabs">
                        <li class="active" (click)="onTabChange('customers')"><a data-toggle="tab" href="#listTab_customersTab"><i class="fas fa-user"
                                    title="Customers"></i></a></li>
                        <li (click)="onTabChange('contracts')"><a data-toggle="tab" href="#listTab_contractsTab"><i class="fas fa-file-signature"
                                    title="Contract"></i></a></li>
                        <li (click)="onTabChange('services')"><a data-toggle="tab" href="#listTab_servicesTab"><i class="fas fa-tasks"
                                    title="Services"></i></a></li>
                        <li (click)="onTabChange('indicators')"><a data-toggle="tab" href="#listTab_metricsTab"><i class="fas fa-percent"
                                    title="Metrics"></i></a></li>
                        <li (click)="onTabChange('thresholds')"><a data-toggle="tab" href="#listTab_thresholdTab"><i class="fas  fa-stream"
                            title="Thresholds"></i></a></li>
                        <li (click)="onTabChange('parameters')"><a data-toggle="tab" href="#listTab_parametersTab"><i class="fas fa-cheese"
                                    title="Parameters"></i></a></li>
                        <li style="display: none"><a data-toggle="tab" href="#listTab_attributesTab"><i class="fab fa-elementor"
                                    title="Attributes"></i></a></li>
                        <li style="display: none"><a data-toggle="tab" href="#listTab_workflowsTab"><i class="fas fa-stream"
                                    title="WorkFlow"></i></a></li>
                        
                    </ul>
                    <div class="tab-content">
                        <div id="listTab_customersTab" class="tab-pane fade  in active">
                            
                            <h3 style="color: #7d2317;">Customers</h3>
                            <div class="" role="group" >
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.List.Customers">
                                            <td><input type="checkbox" id="{{item}}" name="listTab_customersTab" value="{{item.key}}"></td>
                                            <td >{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #listTab_customersTab></div>
                        </div>
                        <div id="listTab_contractsTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Contract</h3>
                            <div class="" role="group" >
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.List.Contracts">
                                            <td><input type="checkbox" id="{{item}}" name="listTab_contractsTab" value="{{item.key}}"></td>
                                            <td >{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #listTab_contractsTab></div>
                        </div>
                        <div id="listTab_servicesTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Services</h3>
                            <div class="" role="group" >
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.List.Services">
                                            <td><input type="checkbox" id="{{item}}" name="listTab_servicesTab" value="{{item.key}}"></td>
                                            <td >{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #listTab_servicesTab></div>
                        </div>
                        <div id="listTab_metricsTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Metrics</h3>
                            <div class="" role="group" >
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.List.Indicators">
                                            <td><input type="checkbox" id="{{item}}" name="listTab_metricsTab" value="{{item.key}}"></td>
                                            <td>{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #listTab_metricsTab></div>
                        </div>
                        
                        <div id="listTab_thresholdTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Thresholds</h3>
                            <div class="" role="group" >
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.List.Thresholds">
                                            <td><input type="checkbox" id="{{item}}" name="listTab_thresholdTab" value="{{item.key}}"></td>
                                            <td >{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #listTab_thresholdTab></div>
                        </div>


                        <div id="listTab_parametersTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Parameters</h3>
                            <div class="" role="group" >
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.List.Paramters">
                                            <td><input type="checkbox" id="{{item}}" name="listTab_parametersTab" value="{{item.key}}"></td>
                                            <td >{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #listTab_parametersTab></div>
                        </div>
                        <div id="listTab_attributesTab" class="tab-pane fade" style="display: none">
                            <h3 style="color: #7d2317;">Attirbutes</h3>
                            <div class="btn-group-vertical" role="group" >
                                <div *ngFor="let item of Tabs.List.Attributes">
                                    <input type="checkbox" id="{{item}}" name="listTab_attributesTab"  value="{{item.key}}">
                                    <label for="{{item}}">{{customizeView(item.val)}}</label><br>
                                </div>
                            </div>
                        </div>
                        <div id="listTab_workflowsTab" class="tab-pane fade" style="display: none">
                            <h3 style="color: #7d2317;">WorkFlow</h3>
                            <p>Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta
                                sunt
                                explicabo.</p>
                        </div>
                       
                        
                    </div>
                </div>
            </div>
  
  
  
  
  
            <!-- List Tab Section Ends Here -->
  
  
  
  
            <div id="tableTab" class="tab-pane fade">
                <div style="margin-top: 2%;width: 100%;">
                    <ul  id="tableTabSectionTabs"  class="nav nav-tabs">
                        <li class="active" (click)="onTabChange('customers')"><a data-toggle="tab" href="#tableTab_customersTab"><i class="fas fa-user"
                                    title="Customers"></i></a></li>
                        <li (click)="onTabChange('contracts')"><a data-toggle="tab" href="#tableTab_contractsTab"><i class="fas fa-file-signature"
                                    title="Contract"></i></a></li>
                        <li (click)="onTabChange('services')"><a data-toggle="tab" href="#tableTab_servicesTab"><i class="fas fa-tasks"
                                    title="Services"></i></a></li>
                        <li (click)="onTabChange('indicators')"><a data-toggle="tab" href="#tableTab_metricsTab"><i class="fas fa-percent"
                                    title="Metrics"></i></a></li>
                        <li (click)="onTabChange('thresholds')"><a data-toggle="tab" href="#tableTab_thresholdTab"><i class="fas  fa-stream"
                            title="Thresholds"></i></a></li>
                        <li (click)="onTabChange('parameters')"><a data-toggle="tab" href="#tableTab_parametersTab"><i class="fas fa-cheese"
                                    title="Parameters"></i></a></li>
                        <li style="display: none"><a data-toggle="tab" href="#tableTab_attributesTab"><i class="fab fa-elementor"
                                    title="Attributes"></i></a></li>
                        <li style="display: none"><a data-toggle="tab" href="#tableTab_workflowsTab"><i class="fas fa-stream"
                                    title="WorkFlow"></i></a></li>
                       
                    </ul>
                    <div class="tab-content">
                        <div id="tableTab_customersTab" class="tab-pane fade  in active">
                            <h3 style="color: #7d2317;">Customers</h3>
                            <div class="" role="group" >
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.Table.Customers">
                                            <td><input type="checkbox" id="{{item}}" name="tableTab_customersTab" value="{{item.key}}"></td>
                                            <td >{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #tableTab_customersTab></div>
                        </div>
                        <div id="tableTab_contractsTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Contract</h3>
                            <div class="" role="group" >
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.Table.Contracts">
                                            <td><input type="checkbox" id="{{item}}" name="tableTab_contractsTab" value="{{item.key}}"></td>
                                            <td >{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #tableTab_contractsTab></div>
                        </div>
                        <div id="tableTab_servicesTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Services</h3>
                            <div class="" role="group" >
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.Table.Services">
                                            <td><input type="checkbox" id="{{item}}" name="tableTab_servicesTab" value="{{item.key}}"></td>
                                            <td >{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #tableTab_servicesTab></div>
                        </div>
                        <div id="tableTab_metricsTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Metrics</h3>
                            <div class="" role="group" >
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.Table.Indicators">
                                            <td><input type="checkbox" id="{{item}}" name="tableTab_metricsTab" value="{{item.key}}"></td>
                                            <td>{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #tableTab_metricsTab></div>
                        </div>
                        <div id="tableTab_thresholdTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Thresholds</h3>
                            <div class="" role="group" >
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.Table.Thresholds">
                                            <td><input type="checkbox" id="{{item}}" name="tableTab_thresholdTab" value="{{item.key}}"></td>
                                            <td >{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #tableTab_thresholdTab></div>
                        </div>
                        <div id="tableTab_parametersTab" class="tab-pane fade">
                            <h3 style="color: #7d2317;">Parameters</h3>
                            <div class="" role="group" >
                                <table class="table table-sm table-striped mytable">
                                    <tbody>
                                        <tr *ngFor="let item of Tabs.Table.Paramters">
                                            <td><input type="checkbox" id="{{item}}" name="tableTab_parametersTab" value="{{item.key}}"></td>
                                            <td >{{customizeView(item.val)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 id="filterMessage"></h4>
                            <div #tableTab_parametersTab></div>
                        </div>
                        <div id="tableTab_attributesTab" class="tab-pane fade" style="display: none">
                            <h3 style="color: #7d2317;">Attirbutes</h3>
                            <button type="button" name="add" id="add" class="btn btn-primary multiDropDownFilter" >Add Filter</button>
                            <h4 id="filterMessage"></h4>
                            <div #tableTab_attributesTab></div>
                            <div class="btn-group-vertical" role="group" >
                                <div *ngFor="let item of Tabs.Table.Attributes">
                                    <input type="checkbox" id="{{item}}" name="tableTab_attributesTab"  value="{{item.key}}">
                                    <label for="{{item}}">{{customizeView(item.val)}}</label><br>
                                </div>
                            </div>
                        </div>
                        <div id="tableTab_workflowsTab" class="tab-pane fade" style="display: none">
                            <h3 style="color: #7d2317;">WorkFlow</h3>
                            <p>Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta
                                sunt
                                explicabo.</p>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
  
    <div Id="sectionThree" class="mainSection"  style="margin-top: 2%;">
        <h4 id="alertArea"></h4>
        <!-- <button class="btn btn-secondary" onclick="addConrol(event)" style="float: left;margin-left: 1%;" id="add-content-in-control">Add Control</button> -->
        <button class="btn btn-secondary"  (click)="insertContentControl()"  style="width: 100%;" id="add-content-in-control">Add Control</button>
        <br>
        <button *ngIf="!loading" class="btn btn-success"  (click)="startPublish()" style="width: 50%" id="replace-content-in-control">Quick Publish</button>
        <button *ngIf="loading" class="btn btn-success" style="width: 50%" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
        </button>
        <button class="btn btn-success" id="messPublish" (click)="messPublish()" style="width: 50%; display: none;">Massive Publish</button>
        <button class="btn btn-success" id="saveTemplate" (click)="saveTemplate()" style="width: 50%;">Save Template</button>
    </div>

  </main>
